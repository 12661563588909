import Vue from 'vue';
import VueRecaptcha from 'vue-recaptcha';

Vue.component('form-component', {
    data: function () {
        return {
            hasCaptcha: false,
            captchaExecuted: false,
            inputs: []
        }
    },
    mounted: function() {
        this.inputs = this.$el.querySelectorAll('.form__input');

        for (let i = 0, input; input = this.inputs[i]; i++) {
            input.addEventListener('input', e => {
                const value = e.target.value.trim();

                if (value) {
                    e.target.dataset.state = 'filled'
                } else {
                    e.target.dataset.state = ''
                }
            })
        }
    },
    components: {
        'vue-recaptcha': VueRecaptcha
    },
    methods: {
        submit: function () {
            this.$validator.validateAll().then(result => {
                let form = this.$el;
                let analytics = null;

                if(form.dataset.analytics){
                    analytics = form.dataset.analytics;
                }

                if (result && analytics) {
                    if (!this.hasCaptcha || this.captchaExecuted) {
                        this.$root.submitForm(form, analytics);
                        this.resetCaptcha();
                    } else {
                        this.$refs.recaptcha.execute();
                    }
                } else if (result) {
                    this.$root.submitForm(form);
                    this.resetCaptcha();
                }
            });
        },
        onCaptchaVerified: function () {
            this.captchaExecuted = true;
            this.submit();
        },
        resetCaptcha: function() {
            this.$refs.recaptcha.reset();
            this.captchaExecuted = false;
        },
    },
});
