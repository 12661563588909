import Vue from 'vue';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

Vue.component("header-component", {
    data: function () {
        return {
            scrolled: false,
            menuExpanded: false,
            headerInverted: false,
        }
    },
    mounted: function () {
        let that = this;
        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });

        window.addEventListener("orientationchange", function() {
            if(screen.orientation.angle == 90) {
                that.menuExpanded = false
            }
        });

        clearAllBodyScrollLocks();

        this.watchScrollPosition();
    },
    watch: {
        menuExpanded: function(newValue) {
            if(newValue) {
                disableBodyScroll(this.$refs.scrollLock);
            } else {
                enableBodyScroll(this.$refs.scrollLock);
            }
        }
    },
    methods: {
        watchScrollPosition: function (){
            let that = this;
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

            if (scrollTop > 50) {
                that.scrolled = true;
            } else {
                that.scrolled = false;
            }
        }
    },
});
