// const store = new Vuex.Store({
//     state: {
//         isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
//     },
//     mutations: {
//     },
//     actions: {
//     },
//     getters: {
//         isMobile() {
//             return store.state.isMobile;
//         },
//     }
// });
