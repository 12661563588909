import Vue from 'vue';

Vue.component("impression", {
    methods: {
        scrollToContent: function() {
            // let scrollPos = document.querySelector('.page-intro').getBoundingClientRect().top + window.scrollY
            // let scrollOffset = window.outerWidth < 768 ? 100 : 150
            //
            // window.scroll({
            //     top: scrollPos - scrollOffset,
            //     behavior: 'smooth'
            // })
        }
    },
});
