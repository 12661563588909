import Vue from 'vue';

Vue.component("navigation-list-item", {
    data: function () {
        return {
            itemExpanded: false,
            headerInverted: false,
        }
    },
    mounted: function () {
        let that = this;

        window.addEventListener("orientationchange", function() {
            if(screen.orientation.angle == 90) {
                that.itemExpanded = false
            }
        });
    },
    methods: {
        expandItem: function(e) {
            if(window.outerWidth < 1300) {
                e.preventDefault();
                e.stopPropagation();
                this.itemExpanded = !this.itemExpanded;
            }
        },
        setHeaderInverted() {
            this.$emit('setheaderinvertedevent')
        },
        resetHeaderInverted() {
            this.$emit('resetheaderinvertedevent')
        },
    },
});
